import React, { Component } from 'react';

export class Titulo extends Component {
   
            render() {         
                function renderi(titulo) {
                    if (titulo!==undefined) {
                        return (
                            
                            <div className=" offset-md-1 col-lg-10 col-md-10 text-center bordesti">
                                <h3 className="borderti">{titulo}</h3>
                            </div>
                        )
                    }
                    else {
                        return (
                            <div className="col-lg-10 col-md-10 offset-md-1 text-center bordesti">
                              <h2 className="borderti">Cargando Título...</h2>            
                          </div>
                        )
                      }
                }
                return renderi(this.props.titulo);
            }
}
        

export default Titulo;