import React, { Component } from 'react';
import logo from '../images/kaltexlogo.jpeg';
export default class Navigation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activo:this.props.activo
        }
    }
    
    
  
    render() { 
        const {        
            activo
          } = this.state;     
        return (          
            <header>
                <div className="container">
                    <div className="row header-cnt">
                        <div className="col-md-4 col-sm-4 col-xs-4">
                            <a className="mburger mburger--collapse" href="#my-menu">
                                <b></b>
                                <b></b>
                                <b></b>
                            </a>
                            <nav id="my-menu" className="navbar-expand-sm" >
                                
                                <ul className="navbar-nav">
                                   
                                   <li className="nav-item ">
                                    <a href={ `/admin/modequipos` } className="menu-item">Configuración Equipos</a>
                                    </li>
                                    <li className="nav-item ">
                                    <a href={ `/admin/modsitios` } className="menu-item">Configuración Sitios</a>
                                    </li>
                                    <li className="nav-item ">
                                    <a href={ `/admin/modnormac` } className="menu-item">Configuración Norma Conagua</a>
                                    </li>
                                    <li className="nav-item ">
                                    <a href={ `/mapa` } className="menu-item">Mapa</a>
                                    </li>
                                </ul>
                                
                            </nav>
                        </div>
                        <div className="col-md-4 col-sm-4 col-xs-4">
                            <div className="logo-img" title="Simas Logo">
                                <a href={ `/mapa` }><img src={logo} alt="Logo"/></a>
                            </div>
                        </div>
                       
                    <div className="row menu">
                        <nav className="navbar-expand-sm">
                            <ul className="navbar-nav">
                                 
                                
                                {(activo==='equipos') ? (<li className="nav-item active" > <a href={ `/admin/modequipos` }>Configuración Equipos</a>
                                </li>) : (<li className="nav-item"> <a href={ `/admin/modequipos` }>Configuración Equipos</a>
                                </li>)}
                                {(activo==='alertas') ? (<li className="nav-item active" > <a href={ `/admin/modequipos` }>Configuración Sitios</a>
                                </li>) : (<li className="nav-item"> <a href={ `/admin/modsitios` }>Configuración Sitios</a>
                                </li>)}
                                {(activo==='normac') ? (<li className="nav-item active" > <a href={ `/admin/modnormac` }>Configuración Norma Conagua</a>
                                </li>) : (<li className="nav-item"> <a href={ `/admin/modnormac` }>Configuración Norma Conagua</a>
                                </li>)}

                                <li className="nav-item"> <a href={ `/mapa` }>Mapa</a></li>
                                
                            </ul>
                        </nav>
                    </div>
                </div>
              </div>
            </header>          
        )
    }
}
